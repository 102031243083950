///src/views/TicketCheckoutViews/utils/errorTrackingUtils.ts
import { mergeQueryParameters } from '@/utils/UriBuilder'
import { TypedTrackFn } from '@/utils/analytics'
import { logger } from '@/utils/logging'

export type TheatricalErrorContext =
  | 'InitializeReservation'
  | 'SetShowtimes'
  | 'SetSeatQuantity'
  | 'SetSeatIds'
  | 'CommitReservation'
  | 'CommitPreorderReservation'
  | 'GetReservation'
  | 'RenewReservation'
  | 'SetTicketPif'
  | 'UpdateReservationEmail'
  | 'CancelReservation'
  | 'InitializeReservationChange'
  | 'GuildReservationSubscription'
  | 'AddPreOrderTicketOption'
  | 'CreatePIFCart'
  | 'InitializeReservationOnError'

export type TheatricalReservationEvent =
  | 'Initialized Ticket Reservation'
  | 'Tickets Showtime Selected'
  | 'Seat Pressed'
  | 'Reserved Seats'
  | 'Set Seat Quantities'
  | 'Committed Ticket Reservation'
  | 'Reservation Known Errors'
  | 'Reservation Unexpected Error'
  | 'Theatrical User Contacted Support'

interface TrackUnexpectedOptions {
  context: TheatricalErrorContext
  error: { message: string }
  track: TypedTrackFn
  uuid: string
  reservationId: string
  extraMetadata?: Record<string, unknown>
}

export const trackUnexpectedReservationError = ({
  context,
  track,
  error,
  reservationId,
  extraMetadata = {},
}: TrackUnexpectedOptions) => {
  if (error?.message) {
    track('Reservation Unexpected Error', { errorMessage: error.message, error, reservationId, ...extraMetadata })
    return logger().error('Reservation Unexpected Error', { context, message: error.message, ...extraMetadata }, error)
  }
  track('Reservation Unexpected Error', { error, reservationId })
  logger().error('Reservation Unexpected Error', { context, ...extraMetadata }, error)
}

export type KnownReservationErrors = { reference: string; description: string }[]
interface TrackKnownOptions {
  context: TheatricalErrorContext
  errors: KnownReservationErrors
  track: TypedTrackFn
  reservationId: string
}

export const trackKnownReservationError = ({ context, track, errors, reservationId }: TrackKnownOptions) => {
  track('Reservation Known Errors', { errors, context, reservationId })
}

export interface TrackUserContactOptions {
  context: string
  uuid: string
  track: TypedTrackFn
  reservationId?: string
}

export const trackUserContactSupport = ({ context, uuid, track, reservationId }: TrackUserContactOptions) => {
  logger().info('User Contacted Support', { context, uuid, reservationId })
  track('User Contacted Support', { uuid, context, reservationId })
}

interface ZendeskLinkProps {
  reservationId: string
  email?: string
  errorId?: string
}
export const createZendeskLink = ({ reservationId, email, errorId }: ZendeskLinkProps) => {
  const baseParams = {
    ticket_form_id: '6742195490455',
    tf_anonymous_requester_email: email ?? '',
    tf_19423688123671: reservationId,
    tf_6742404389015: 'computer_web_page_field',
  }
  if (errorId) {
    return mergeQueryParameters('https://support.angel.com/hc/requests/new', {
      ...baseParams,
      tf_19579737983127: errorId,
    })
  }
  return mergeQueryParameters('https://support.angel.com/hc/requests/new', {
    ...baseParams,
  })
}
